<template>
  <q-btn
    color="dark"
    :icon-right="t('icons.handRight')"
    :label="t('cart.button.label')"
    no-caps
    square
    :to="{ name: 'cart' }"
  />
</template>

<script setup>
import { i18n } from "src/boot/i18n";

defineOptions({ name: "CartPageBtn" });

const { t } = i18n.global;
</script>
