<template>
  <q-card class="bg-primary-fade"
    ><q-card-section class="text-center text-h6">{{
      cart.discountCodes[0].code
    }}</q-card-section></q-card
  >
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCartStore } from "src/stores/cart";

defineOptions({ name: "CartDiscountCard" });

const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);
</script>
