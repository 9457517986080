<template>
  <div />
</template>

<script setup>
import { shallowRef, watch } from "vue";
import { useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import { useTimeoutFn } from "@vueuse/core";
import { useToastStore } from "src/stores/toast";
import ToastTemplate from "./ToastTemplate.vue";

defineOptions({ name: "ToastSwitch" });

const $q = useQuasar();
const toastStore = useToastStore();
const { toastData, toastType, showToast } = storeToRefs(toastStore);
const toastComponent = shallowRef(null);
let toast;

function show() {
  switch (toastType.value) {
    default:
      toastComponent.value = ToastTemplate;
      break;
  }
  toast = $q
    .dialog({
      component: toastComponent.value,
      componentProps: {
        data: toastData.value,
        type: toastType.value,
      },
    })
    .onOk(() => {})
    .onCancel(() => {})
    .onDismiss(() => {
      toastStore.hide();
    });
  const { start } = useTimeoutFn(() => {
    if (toast) toast.hide();
  }, 4000);
  start();
}

watch(showToast, (showToast) => {
  if (showToast) show();
});
</script>
