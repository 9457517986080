<template>
  <q-dialog
    class="no-backdrop"
    ref="dialogRef"
    style="z-index: 6002"
    @show="onDialogShow"
    @hide="onDialogHide"
  >
    <div :class="{ 'q-mb-xl': $q.screen.gt.sm }">
      <div v-if="showing" class="q-mb-lg q-mx-auto">
        <circle-text-page-title :radius="400" :title="mappedTitle" />
      </div>
      <component
        :is="mappedComponent"
        class="q-dialog-plugin q-mb-xl q-mx-auto"
        :line-item="data.lineItem"
        ref="boundingRef"
      />
      <div class="text-center">
        <component :is="mappedButton" />
      </div>
    </div>
    <q-resize-observer @resize="(size) => onResize(size)" />
  </q-dialog>
</template>

<script setup>
import { computed, inject, onMounted, nextTick, ref } from "vue";
import { useDialogPluginComponent, useTimeout, useQuasar } from "quasar";
import { i18n } from "src/boot/i18n";
import CartCard from "src/components/cart/CartCard.vue";
import CartCheckoutBtn from "src/components/cart/CartCheckoutBtn.vue";
import CartDiscountCard from "src/components/cart/CartDiscountCard.vue";
import CartPageBtn from "src/components/cart/CartPageBtn.vue";
import CircleTextPageTitle from "src/components/circleText/CircleTextPageTitle.vue";

defineOptions({ name: "ToastTemplate" });

const props = defineProps({
  data: { type: Object, default: () => {} },
  type: { type: String, default: "default" },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const $q = useQuasar();
const { registerTimeout } = useTimeout();
const bus = inject("bus");
const { t } = i18n.global;
const boundingRef = ref(null);
const showing = ref(false);

const componentMap = {
  inYourCart: CartCard,
  discountCodeApplied: CartDiscountCard,
};

const buttonMap = {
  inYourCart: CartPageBtn,
  discountCodeApplied: CartCheckoutBtn,
};

const titleMap = {
  inYourCart: t("cart.toast.title"),
  discountCodeApplied: t("cart.discount.toast.title"),
};

const mappedComponent = computed(() => componentMap[props.type] || null);
const mappedButton = computed(() => buttonMap[props.type] || null);
const mappedTitle = computed(() => titleMap[props.type] || "");

const handleResize = (size) => {
  const boundingRect = boundingRef.value?.$el?.getBoundingClientRect() || null;
  const boundingSize = {
    height: boundingRef.value?.$el?.clientHeight || 64,
    width: boundingRef.value?.$el?.clientWidth || 64,
  };
  const boundingPosition = {
    left: boundingRect?.left || size.width / 2,
    top: boundingRect?.top || size.height / 2,
  };
  const screenSize = {
    height: size.height,
    width: size.width,
  };
  const data = {
    boundingSize,
    boundingPosition,
    screenSize,
  };
  bus.emit("boundaryResize", data);
};

const onDialogShow = () => {
  nextTick(() => {
    handleResize($q.screen);
  });
};

const onOKClick = () => {
  onDialogOK();
};

const onResize = (size) => {
  nextTick(() => {
    handleResize(size);
  });
};

onMounted(async () => {
  await nextTick();
  registerTimeout(() => {
    showing.value = true;
  }, 800);
});
</script>
